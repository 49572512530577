























































































.main-container{
    height: 100%;
    width: 100%;
}
.consumible-item-container{
    display: inline-block;
}
.items-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.select-filters-container{
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.select-consumibles{
    width: 300px;
}
.overlay{
    background-color: red;
    height: 100%;
}
  
