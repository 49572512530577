












































































































































































































#secondary-container{
  height:100%;
  width:100%;
}

.primary-container{
  height:93%;
  width:100%;
}

.iframe-container{
  height:100% !important;
}

header{
  display:none !important;
}

.next-courses-container {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #444242;
  border-bottom: 2px solid black;
}

.back-tool-btn {
  width: 100%;
  max-width: 300px;
  background: #3fadd8;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  margin: auto;
  font-weight: bold;
}

a{
  text-decoration: none;
}

.content-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-membership{
  font-weight: bold;
  font-size: 25px;
}

.advanced-course-content{
  background-color: #FF9B00;
  padding: 2px 180px 2px 180px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.advanced-course-content-mobile{
  text-align: center;
  background-color: #FF9B00;
}

.advanced-course-text{
  font-size: 19px;
}

.link-to-online-advanced-course{
  text-decoration: underline;
}

.pricing-wrapper-mobile{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tab-class{
  width: 92%;
}
.cart-container{
  float: right;
  margin-right: 60px;
  margin-top: 10px;
}
.cart-container:hover{
  cursor: pointer;
}
.icon-cart{
  font-size: 35px;
  color: var(--color-text-primary);
}
.cart-text{
  font-size: 14px;
  font-weight: bold;
  color: var(--color-text-primary);
}
.header-store{
  display: flex;
}
.number-cart-products{
  color: red;
  position: absolute;
  top: 3px;
  font-weight: bold;
  font-size: 20px;
}

.main-title-container{
  text-align: center;
}

.courses-wrapper{
  margin-top: 20px !important;
  width: 50%;
  margin: auto;
  display: inline;
  text-align: -webkit-center;
  height: 100%;
}

.basic-course-container{
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  width: 50%;
  height: 100%;
}

.advanced-course-container{
  margin-top: 50px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  padding: 20px;
  width: 50%;
  height: 100%;
}

.link-to-eurekers-courses{
  display: block;
  background-color: #00add9;
  color: white;
  text-align: center;
  border-radius: 20px;
  height: 25px;
  width: 200px;
  padding: 10px;
}

.link-to-eurekers-courses:active{
  transform: translateY(2px);
}

.title{
  color: #0F0F1A;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-weight: 600;
  margin-bottom: 35px;
}

.subtitle{
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 35px;
}

