





















































































































































.consumible-container{
    border: 1px solid black;
    background-color: white;
    width: 300px;
    height: 450px;
    transition: transform 0.2s ease-out;
}
.consumible-container:hover{
    transform: scale(1.05);
}
.text-container{
    text-align: center;
    margin-bottom: 20px;
    display: grid;
}
.quantity-container{
    text-align: center;
    margin-bottom: 20px;
}
.buy-container{
    text-align: center;
}
label {
  font-weight: bold;
}

input[type="number"] {
  width: 30px;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}
#add-to-cart{
    background-color: #FCD200;
    margin-bottom: 20px;
    color: black;
}
#buy-now{
    background-color: orange;
    color: black;
}
.price-text{
    font-weight: bold;
    font-size: 24px;
    margin: 5px 0 5px 0;
}
.title-text{
    font-size: 24px;
}
.consumible-container {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .patata {
    position: relative;
  }
  .block-icon{
    color: white;
    font-size: 345px;
    opacity: 0.6;
  }
  
