
























































.pop-up-container{
    position:absolute;
    width: 96.5vw;
    height: 99.99vh;
    background-color: rgb(95, 95, 95, 0.75);}
.confirm-redirect{
    position:absolute;
    width: 45vw;
    height: 35vh;
    background-color: white;
    top: 30%;
    left: 26%;
    opacity: 1;
    border: 8px solid #00add8;
    border-radius: 50px;
}

.pop-up-text{
    padding: 10px;
    text-align: center;
}
.pop-up-buttons{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10%;
}

#cancel-button{
    background-color: red;
}
